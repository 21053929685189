<template>
  <div class="body" ref="DomBody" v-loading="saveLoading">
    <!-- 配置 -->
    <div class="setting">
      <!-- 表头 -->
      <div class="titleArea btnGroup">
        <!-- 模板选择 -->
        <div>
          <el-dropdown>
            <div style="display: flex; align-items: center; cursor: pointer">
              <div
                style="
                  margin-right: 5px;
                  width: 100px;
                  text-align: center;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
              >
                {{ templateName }}
              </div>
              <div class="el-icon-caret-bottom"></div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <div
                class="singleObj objTitle"
                @click="
                  choseTemplate(index, item.templateid, item.subid, item.type)
                "
                v-for="(item, index) in templateList"
                :key="index"
                :style="{
                  background:
                    item.templatename == templateName ? '#ECF5FF' : '#fff',
                  color:
                    item.templatename == templateName ? '#7ac2fd' : '#333333',
                }"
              >
                {{ item.templatename }}
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- 标题 -->
        <!-- 适用对象 front-objectTemplate-V1-UseObject-->
        <div style="margin: 0px 20px">
          {{ $t("front-objectTemplate-V1-UseObject") }}
        </div>
        <!-- 输入框 -->
        <div>
          <el-select
            v-model="parentObject"
            placeholder=""
            filterable
          >
            <el-option
              v-for="item in objectList"
              @click.native="tabObject(item.prefix)"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin: 0px 20px">
          {{ $t("label.appupload.component.type.recordtype") }}
        </div>
        <!-- 记录类型 -->
        <div>
          <el-select v-model="TemplateRecordType" placeholder="" filterable>
            <el-option
              v-for="item in recordTypeData"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        :class="[this.isFixed ? 'fixedDom' : '']"
        :style="{ width: this.DomWidth + 'px' }"
      >
        <!-- 按钮组 -->
        <!-- 保存 component.telerecord.button.save-->
        <!-- 保存并预览 front-template-V1-saveUse-->
        <!-- 取消 label.emailsync.button.cancel-->
        <div class="btnGroup">
          <el-button @click="saveReportTemplate">{{
            $t("component.telerecord.button.save")
          }}</el-button>
          <el-button @click="viewTemplate">{{
            $t("front-template-V1-saveUse")
          }}</el-button>
          <el-button @click="cancelFun">{{
            $t("label.emailsync.button.cancel")
          }}</el-button>
          <span>|</span>
          <!-- 重做 lable.redo-->
          <el-button icon="el-icon-refresh-right" @click="redo">{{
            $t("lable.redo")
          }}</el-button>
        </div>
        <!-- 对象列表 -->
        <div class="dataList">
          <div class="objList">
            <div
              @click="updateObj(item)"
              class="objItem"
              v-for="(item, index) in objList"
              :key="index"
              :style="{
                background: item.fieldid == objFieldId ? '#E0E0E0' : '',
                fontWeight: item.fieldid == objFieldId ? 'bold' : '',
              }"
            >
              {{ item.objname }}{{ `(${item.labelName})` }}
            </div>
          </div>
          <div class="data" v-loading="loadOption">
            <!-- 查找 -->
            <div class="search">
              <!-- 快速查找 lable.Quick.find-->
              <div>{{ $t("lable.Quick.find") }}</div>
              <div style="margin: 0px 20px">
                <!-- 字段 setup.layout.group.field-->
                <!-- 输入字段名，快速搜索 front-template-V1-quikSearch-->
                <el-input
                  v-model="searchObjectValue"
                  :placeholder="$t('front-template-V1-quikSearch')"
                  @input="objectFieldIsempty"
                  @keyup.enter.native="searchObjectField"
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-search"
                    @click="searchObjectField"
                  ></i>
                </el-input>
              </div>
            </div>
            <!-- 列表 -->
            <div class="listF">
              <ul class="fieldList">
                <vuedraggable
                  v-model="ObjectField"
                  animation="300"
                  :options="groupType"
                  :disabled="disabled"
                  filter=".DisableInputItem"
                  @choose="chooseType"
                  @unchoose="unchooseType"
                  @end="isRepeat"
                  :move="NotField"
                >
                  <transition-group style="display: flex; flex-wrap: wrap">
                    <!-- isDisable 已经存在字段禁用-->
                    <!-- Listdisabled 如果是客户和联系人则禁用列表拖拽项-->
                    <li
                      :class="[
                        item.isDisable && item.id != '0001' && item.id != '0002'
                          ? 'DisableInputItem'
                          : 'inputItem',
                      ]"
                      v-for="(item, index) in ObjectField"
                      :key="index"
                      :title="item.labelName"
                      :id="[item.id == '0001' ? 'isList' : '']"
                    >
                      <!-- 箭头 -->
                      <i
                        v-if="
                          item.id == '0001' ||
                          item.id == '0002' ||
                          item.id == '0003' ||
                          item.id == '0004'
                        "
                        class="el-icon-right position"
                      ></i>
                      <div
                        v-if="
                          item.id == '0001' ||
                          item.id == '0002' ||
                          item.id == '0003' ||
                          item.id == '0004'
                        "
                        class="position"
                        style="left: 22px; top: 7px"
                      >
                        <img src="@/assets/templateSetting/part.png" alt="" />
                      </div>
                      <!-- <input
                    readonly
                    prefix-icon="el-icon-right"
                    v-model="item.labelName"
                    :class="[item.id == '0001' || item.id == '0002' ? 'backgroundColor' : '']"
                  /> -->
                      <div
                        :class="[
                          item.id == '0001' ||
                          item.id == '0002' ||
                          item.id == '0003' ||
                          item.id == '0004'
                            ? 'backgroundColor'
                            : '',
                          'fieldtext',
                        ]"
                      >
                        {{ item.labelName }}
                      </div>
                    </li>
                  </transition-group>
                </vuedraggable>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 模块二 -->
    <div
      class="moduleT"
      :style="{ marginTop: this.isFixed ? '230px' : '15px' }"
    >
      <!-- 基础信息 -->
      <vuedraggable
        v-model="templateContentList"
        group="partList"
        animation="300"
      >
        <transition-group>
          <div
            class="templateContent"
            v-for="(item, index) in templateContentList"
            :key="index"
          >
            <!-- 标题 -->
            <div
              class="partTitle"
              @mouseover="partEnter(index)"
              @mouseleave="partLeave(index)"
            >
              <span
                class="contentTitle"
                :style="{
                  color: item.HideTitle != '1' ? '#666666' : '#dcdcdc',
                }"
              >
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-biaoti"></use>
                </svg>
                {{ item.title }}</span
              >
              <span class="optionIcon" v-show="item.iconStatus">
                <i
                  class="el-icon-remove"
                  @click="removePart(index)"
                  v-show="item.iconStatus && templateContentList.length > 1"
                ></i>
                <i
                  class="el-icon-setting"
                  @click="settingPart(index, item)"
                ></i>
              </span>
            </div>
            <!-- 数据 -->
            <div :class="[item.type == 'list' ? 'listData' : 'contentData']">
              <!--部分 左右布局 -->
              <div class="layoutBox" v-if="item.type == 'section'">
                <!-- 左边 -->
                <div class="layoutBox-left">
                  <vuedraggable
                    v-model="item.leftData"
                    animation="300"
                    group="fieldgroup"
                    @choose="chooseDisabledleft"
                    @unchoose="unchooseDisabledleft"
                  >
                    <transition-group>
                      <div
                        @mouseover="fieldListEnterLeft(index, Sindex)"
                        @mouseleave="fieldListLeaveLeft(index, Sindex)"
                        :class="[
                          'contentDataItem',
                          Sitem.id != 'isempty' ? 'contentDataItemLeft' : '',
                        ]"
                        v-for="(Sitem, Sindex) in item.leftData"
                        :key="Sindex"
                      >
                        <!-- 系统字段 -->
                        <div
                          class="contentDataItem-child-left"
                          v-if="
                            Sitem.type != 'text' &&
                            Sitem.type != 'img' &&
                            Sitem.type != 'Space'
                          "
                        >
                          <div class="sampleText">
                            <span class="fieldName">{{ Sitem.labelName }}</span>
                            <!-- 示例文本 lable.sample.text-->
                            <span v-if="Sitem.id != 'isempty'">{{
                              $t("lable.sample.text")
                            }}</span>
                            <span></span>
                          </div>
                          <span class="remove-field">
                            <i
                              class="el-icon-remove"
                              v-show="Sitem.id != 'isempty' && Sitem.isShow"
                              @click="removeLeftField(index, Sindex, Sitem.id)"
                            ></i>
                          </span>
                        </div>
                        <!-- 文本字段 -->
                        <div v-if="Sitem.type == 'text'" class="textField">
                          <span
                            :style="{
                              fontSize: `${Sitem.fontSize}px`,
                              color: Sitem.Color,
                              fontWeight:
                                Sitem.fontWeight == '1' ? 'bold' : 'normal',
                            }"
                            >{{ Sitem.text }}</span
                          >
                          <span class="remove-field">
                            <i
                              class="el-icon-remove"
                              v-show="Sitem.id != 'isempty' && Sitem.isShow"
                              @click="removeLeftField(index, Sindex, Sitem.id)"
                            ></i>
                            <i
                              class="el-icon-setting text-setting"
                              v-show="Sitem.id != 'isempty' && Sitem.isShow"
                              @click="
                                textSetting(Sitem, 'leftData', index, Sindex)
                              "
                            ></i>
                          </span>
                        </div>
                        <!-- 图片 -->
                        <div
                          v-if="Sitem.type == 'img'"
                          class="imgField"
                          @mouseover="fieldListEnterLeft(index, Sindex)"
                          @mouseleave="fieldListLeaveLeft(index, Sindex)"
                        >
                          <img
                            :src="`${userUrl}/chatterfile.action?m=preview&fileid=${Sitem.fileid}&binding=${tocken}`"
                            style="width: 90%; height: 100%"
                            alt=""
                          />
                          <span class="remove-field">
                            <i
                              class="el-icon-remove"
                              v-show="Sitem.id != 'isempty' && Sitem.isShow"
                              @click="removeLeftField(index, Sindex, Sitem.id)"
                            ></i>
                            <i
                              class="el-icon-setting text-setting"
                              v-show="Sitem.id != 'isempty' && Sitem.isShow"
                              @click="imgSetting(index, 'leftData', Sindex)"
                            ></i>
                          </span>
                        </div>
                        <!-- 空格 -->
                        <div v-if="Sitem.type == 'Space'" class="SpaceField">
                          <span> 空格 </span>
                          <span class="remove-field">
                            <i
                              class="el-icon-remove"
                              v-show="Sitem.id != 'isempty' && Sitem.isShow"
                              @click="removeLeftField(index, Sindex, Sitem.id)"
                            ></i>
                          </span>
                        </div>
                      </div>
                    </transition-group>
                  </vuedraggable>
                </div>
                <!-- 右边 -->
                <div class="layoutBox-right">
                  <vuedraggable
                    v-model="item.rightData"
                    animation="300"
                    group="fieldgroup"
                    @choose="chooseDisabledright"
                    @unchoose="unchooseDisabledright"
                  >
                    <transition-group>
                      <div
                        @mouseover="fieldListEnterRight(index, Sindex)"
                        @mouseleave="fieldListLeaveRight(index, Sindex)"
                        :class="[
                          'contentDataItem',
                          Sitem.id != 'isempty' ? 'contentDataItemRight' : '',
                        ]"
                        v-for="(Sitem, Sindex) in item.rightData"
                        :key="Sindex"
                      >
                        <!-- 系统字段 -->
                        <div
                          class="contentDataItem-child-right"
                          v-if="
                            Sitem.type != 'text' &&
                            Sitem.type != 'img' &&
                            Sitem.type != 'Space'
                          "
                        >
                          <div class="sampleText">
                            <span class="fieldName">{{ Sitem.labelName }}</span>
                            <!-- 示例文本 lable.sample.text-->
                            <span v-if="Sitem.id != 'isempty'">{{
                              $t("lable.sample.text")
                            }}</span>
                            <span></span>
                          </div>
                          <span class="remove-field">
                            <i
                              class="el-icon-remove"
                              v-show="Sitem.id != 'isempty' && Sitem.isShow"
                              @click="
                                removeRightField(
                                  index,
                                  'rightData',
                                  Sindex,
                                  Sitem.id
                                )
                              "
                            ></i>
                          </span>
                        </div>
                        <!-- 文本字段 -->
                        <div v-if="Sitem.type == 'text'" class="textField">
                          <span
                            :style="{
                              fontSize: `${Sitem.fontSize}px`,
                              color: Sitem.Color,
                              fontWeight:
                                Sitem.fontWeight == '1' ? 'bold' : 'normal',
                            }"
                            >{{ Sitem.text }}</span
                          >
                          <span class="remove-field">
                            <i
                              class="el-icon-remove"
                              v-show="Sitem.id != 'isempty' && Sitem.isShow"
                              @click="removeRightField(index, Sindex, Sitem.id)"
                            ></i>
                            <i
                              class="el-icon-setting text-setting"
                              v-show="Sitem.id != 'isempty' && Sitem.isShow"
                              @click="
                                textSetting(Sitem, 'rightData', index, Sindex)
                              "
                            ></i>
                          </span>
                        </div>
                        <!-- 图片 -->
                        <div
                          v-if="Sitem.type == 'img'"
                          class="imgField"
                          @mouseover="fieldListEnterRight(index, Sindex)"
                          @mouseleave="fieldListLeaveRight(index, Sindex)"
                        >
                          <img
                            :src="`${userUrl}/chatterfile.action?m=preview&fileid=${Sitem.fileid}&binding=${tocken}`"
                            style="width: 90%; height: 100%"
                            alt=""
                          />
                          <span class="remove-field">
                            <i
                              class="el-icon-remove"
                              v-show="Sitem.id != 'isempty' && Sitem.isShow"
                              @click="removeRightField(index, Sindex, Sitem.id)"
                            ></i>
                            <i
                              class="el-icon-setting text-setting"
                              v-show="Sitem.id != 'isempty' && Sitem.isShow"
                              @click="imgSetting(index, 'rightData', Sindex)"
                            ></i>
                          </span>
                        </div>
                        <!-- 空格 -->
                        <div v-if="Sitem.type == 'Space'" class="SpaceField">
                          <span> 空格 </span>
                          <span class="remove-field">
                            <i
                              class="el-icon-remove"
                              v-show="Sitem.id != 'isempty' && Sitem.isShow"
                              @click="removeLeftField(index, Sindex, Sitem.id)"
                            ></i>
                          </span>
                        </div>
                      </div>
                    </transition-group>
                  </vuedraggable>
                </div>
              </div>
              <!-- 列表 -->
              <div v-if="item.type == 'list'" class="listStyle">
                <table>
                  <tr>
                    <th
                      v-for="listItem in item.tableData"
                      :key="listItem.id"
                      :style="{
                        width:
                          item.tableData.length <= 4
                            ? '25%'
                            : 100 / item.tableData.length + '%',
                      }"
                    >
                      {{ listItem.labelName }}
                    </th>
                  </tr>
                  <tr>
                    <!-- 示例文本 lable.sample.text-->
                    <td
                      v-for="listTd in item.tableData"
                      :key="listTd.id"
                      :style="{
                        width:
                          item.tableData.length <= 4
                            ? '25%'
                            : 100 / item.tableData.length + '%',
                      }"
                    >
                      {{ $t("lable.sample.text") }}
                    </td>
                    <!-- 无意义占位元素-让元素各占25% -->
                    <td v-if="item.tableData.length < 2"></td>
                    <td v-if="item.tableData.length < 3"></td>
                    <td v-if="item.tableData.length < 4"></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </transition-group>
      </vuedraggable>
    </div>
    <!-- 部分弹窗 -->
    <!-- 标题 label.file.title-->
    <!-- 新建部分 label.layout.new.built-->
    <!-- 部分属性 lable.Partial.attributes-->
    <div>
      <el-dialog
        :title="$t('lable.Partial.attributes')"
        :visible.sync="partStatus"
        width="30%"
      >
        <span class="partDailog">
          <span>{{ $t("label.file.title") }}</span>
          <el-input
            v-model="partinput"
            :placeholder="$t('label.layout.new.built')"
          ></el-input>
        </span>
        <div class="titleHide">
          <!-- 隐藏标题 lable.Hide.title-->
          <el-checkbox v-model="PartIsHide">{{
            $t("lable.Hide.title")
          }}</el-checkbox>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- 取消 label.emailsync.button.cancel-->
          <!-- 确定 label.tabpage.ok-->
          <el-button @click="partStatus = false">{{
            $t("label.emailsync.button.cancel")
          }}</el-button>
          <el-button type="primary" @click="addPart">{{
            $t("label.tabpage.ok")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 设置弹窗 -->
    <!-- 部分属性 lable.Partial.attributes-->
    <div>
      <el-dialog
        :title="$t('lable.Partial.attributes')"
        :visible.sync="settingStatus"
        width="30%"
      >
        <span class="partDailog">
          <!-- 标题 label.quote.title-->
          <span>{{ $t("label.quote.title") }}</span>
          <el-input
            v-model="settinginput"
            :placeholder="$t('label.layout.new.built')"
          ></el-input>
        </span>
        <div class="titleHide">
          <!-- 隐藏标题 lable.Hide.title-->
          <el-checkbox v-model="PartSetIsHide">{{
            $t("lable.Hide.title")
          }}</el-checkbox>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="settingStatus = false">{{
            $t("label.emailsync.button.cancel")
          }}</el-button>
          <el-button type="primary" @click="settingPartTitle">{{
            $t("label.tabpage.ok")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
    <div>
      <!-- 列表弹窗 -->
      <!-- 列表属性 lable.List.properties-->
      <el-dialog
        :title="$t('lable.List.properties')"
        :visible.sync="showList"
        width="780px"
        @close="closeDialog"
        @open="openDialog"
      >
        <div class="bodyContent">
          <!-- 标题 label.quote.title-->
          <div class="titlePart">
            <div class="bodyTitle">{{ $t("label.quote.title") + ":" }}</div>
            <div>
              <div>
                <el-input v-model="listName" placeholder=""></el-input>
              </div>
              <div>
                <!-- 隐藏标题 lable.Hide.title-->
                <el-checkbox v-model="ListIsHide">{{
                  $t("lable.Hide.title")
                }}</el-checkbox>
              </div>
            </div>
          </div>
          <!-- 列表 -->
          <div class="bodyList">
            <div class="listButton">
              <!-- 筛选器 label.weixin.report.filters-->
              <!-- 排序 label.sort-->
              <!-- 列数 lable.Number.of.columns-->
              <el-button @click="changeContent('columns')">{{
                $t("lable.Number.of.columns")
              }}</el-button>
              <el-button @click="changeContent('screen')">{{
                $t("label.weixin.report.filters")
              }}</el-button>
              <el-button @click="changeContent('sort')">{{
                $t("label.sort")
              }}</el-button>
            </div>
            <!-- 内容 -->
            <div class="content" v-if="showFilter == 'columns'">
              <!-- 标题 -->
              <!-- 选择一个对象并添加它的最多10个字段到表格中。上移或下移箭头作为列标题出现的字段。 lable.Up.to.ten.fields-->
              <div class="contentTitle">
                {{ $t("lable.Up.to.ten.fields") }}
              </div>
              <!-- 对象选择 -->
              <div class="titlePart">
                <!-- 对象 label.import.index.obj-->
                <!-- -无- label.h5.date.none-->
                <div class="bodyTitle">
                  {{ $t("label.import.index.obj") + ":" }}
                </div>
                <el-select
                  v-model="CurrentObject"
                  :placeholder="$t('label.h5.date.none')"
                  @change="getSubobjectFieldList"
                >
                  <el-option
                    v-for="item in Subobjectlist"
                    :key="item.field_id"
                    :label="item.name"
                    :value="item.field_id"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- 穿梭框 -->
              <div>
                <!-- 标题 -->
                <!-- 显示字段 component.setup.label.displayfield-->
                <!-- 可选字段 label.select.field-->
                <div class="fieldTitle">
                  {{ $t("component.setup.label.displayfield") }}
                </div>
                <div>
                  <div class="hasList hasListLeft">
                    <div class="title">{{ $t("label.select.field") }}</div>
                    <div style="position: relative">
                      <div class="bodyS">
                        <div class="input-box">
                          <div class="inputTitle">
                            <input
                              type="text"
                              @input="isempty"
                              @keyup.enter="searchField"
                              v-model="searchValue"
                            />
                          </div>
                          <img
                            src="@/assets/images/search.png"
                            @click="searchField"
                            style="cursor: pointer"
                            alt
                          />
                        </div>
                        <div class="list">
                          <div
                            class="list-item"
                            v-for="(item, index) in searchSubobjectFieldList"
                            :key="item.id"
                            @mouseover="mouseOver(index)"
                            :style="{
                              background:
                                addCurrentIndex == index ? '#F5F5F5' : '#fff',
                              color:
                                addCurrentIndex == index
                                  ? '#006DCC'
                                  : '#333333',
                            }"
                          >
                            <div class="name">{{ item.labelName }}</div>
                            <div
                              class="symbol"
                              v-show="
                                addCurrentIndex == index &&
                                isSelectPeoList.length < 10
                              "
                              @click="selectPeo(item, index)"
                            >
                              <img src="@/assets/globalSearch/add.png" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="hasList isSelect">
                    <!-- 已选字段 label.chosen.field-->
                    <div class="title">{{ $t("label.chosen.field") }}</div>
                    <div class="bodyS" style="padding-top: 0px">
                      <div class="list" style="padding-top: 0px">
                        <div
                          class="list-item"
                          v-for="(item, index) in isSelectPeoList"
                          :key="item.id"
                          @mouseover="isSelectMouseOver(index)"
                          :style="{
                            background:
                              deleteCurrentIndex == index ? '#F5F5F5' : '#fff',
                            color:
                              deleteCurrentIndex == index
                                ? '#006DCC'
                                : '#333333',
                          }"
                        >
                          <div class="name">{{ item.labelName }}</div>
                          <div
                            class="symbol"
                            v-show="deleteCurrentIndex == index"
                            @click="deletePeo(index)"
                          >
                            <img
                              src="@/assets/globalSearch/reduce.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="PositionFunction">
                    <i class="el-icon-top" @click="FieldTopping"></i>
                    <i class="el-icon-arrow-up" @click="FieldMoveUp"></i>
                    <i class="el-icon-arrow-down" @click="FieldMoveDown"></i>
                    <i class="el-icon-bottom" @click="FieldBottomSetting"></i>
                  </div>
                </div>
              </div>
              <div style="clear: both"></div>
            </div>
            <div class="content" v-show="showFilter == 'screen'">
              <!-- 或者，筛选在相关列表中显示的记录。选择复选框，或选项列表字段和您想要筛选的值。如果您手动输入值，请使用逗号将其割开。 lable.Set.value.prompt-->
              <div class="contentTitle">
                {{ $t("lable.Set.value.prompt") }}
              </div>
              <div class="range">
                <!-- 筛选字段文本框 -->
                <el-form>
                  <el-form-item v-for="(val, index) in pushData" :key="val.id">
                    <div class="vl" style="display: flex">
                      <!-- 筛选字段 开始 -->
                      <!-- 选择字段 setup.layout.label.selectedfield-->
                      <el-select
                        v-model="val.fieldId"
                        :placeholder="$t('setup.layout.label.selectedfield')"
                        filterable
                        popper-class="option"
                        :popper-append-to-body="false"
                      >
                        <el-option
                          v-for="(items, indexs) in choseFieldList"
                          :key="indexs"
                          :label="items.labelName"
                          :value="items.id"
                          @click.native="handleCommand(val, items, items)"
                        >
                          <!-- val:传递当前字段的信息 -->
                          <!-- items:传递 -->
                        </el-option>
                      </el-select>
                      <!-- 筛选条件 开始 -->
                      <!-- 选择条件 label.report.select.condition-->
                      <el-select
                        v-model="val.op"
                        :placeholder="$t('label.report.select.condition')"
                        class="select"
                        filterable
                        popper-class="option"
                        :popper-append-to-body="false"
                      >
                        <el-option
                          v-for="item in conditions"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <EditableCell
                      style="
                          display: inline-block;
                          width:160px
                          position: relative;
                        "
                      ref="editableCell"
                      :options="selector"
                      :value="val.val"
                      :input-type="val.inputType"
                      :max="1000000000000000000"
                      :origin-type="filter"
                      :prefix="prefix"
                      :fieldId="val.fieldId"
                      :objid="val.objid"
                      @selectes="val.fieldId === '' ? '' : selectes(val)"
                      @remoteSearch="remoteSearch(val, index)"
                      @remoteMethods="remoteMethods"
                    >
                      {{ val.fieldId }}
                    </EditableCell>
                    <!-- 加减按钮组 -->
                    <div style="display: flex; margin-left: 5px">
                      <el-button
                        type="info"
                        size="mini"
                        @click="updateItem('reduce', index)"
                        v-if="showBut[index]"
                        >-</el-button
                      >
                      <!-- 清空 label.emailtocloudcc.clear-->
                      <el-button
                        type="info"
                        size="mini"
                        @click="clear"
                        v-if="index == 0"
                        >{{ $t("label.emailtocloudcc.clear") }}</el-button
                      >
                      <!-- <button type=" button" @click="updateItem('reduce', index)" v-if="showBut[index]"></button> -->
                      <el-button
                        type="info"
                        size="mini"
                        @click="updateItem('add', index)"
                        v-if="
                          index + 1 == pushData.length && pushData.length <= 4
                        "
                        >+</el-button
                      >
                      <!-- <button type="button" @click="updateItem('add', index)" v-if="index + 1 == pushData.length">
                +
              </button> -->
                    </div>
                  </el-form-item>
                </el-form>
                <div>
                  <!-- 搜索 label.searchs-->
                  <el-dialog
                    :title="$t('label.searchs')"
                    custom-class="dialog-form-wrapper"
                    width="60%"
                    top="5%"
                    style="height: 91%"
                    :visible.sync="showSearchTable"
                    :lock-scroll="true"
                    :close-on-click-modal="false"
                    :modal="true"
                    append-to-body
                  >
                    <search-table
                      ref="searchTable"
                      :fieldId="fieldId"
                      :checked="checked"
                      :relevant-objid="relevantObjId"
                      :relevant-prefix="relevantPrefix"
                      @changeSelect="changeSelect"
                    />
                  </el-dialog>
                </div>
              </div>
            </div>
            <div v-if="showFilter == 'sort'" class="sort">
              <!-- 通过选择要排序的字段，自定义相关列表中的记录顺序。您可以按对象中的任何数字或日期字段进行排序。lable.Sort.field.tips -->
              <p>
                {{ $t("lable.Sort.field.tips") }}
              </p>
              <div>
                <!-- 排序方式 label.orderby-->
                <!-- -无- label.h5.date.none-->
                <span class="sortOrder">{{ $t("label.orderby") }}</span>
                <el-select
                  v-model="TimeTypeField"
                  :placeholder="$t('label.h5.date.none')"
                  @change="chioceValue"
                >
                  <el-option
                    v-for="item in TimeTypeFieldList"
                    :key="item.id"
                    :label="item.labelName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-model="mode"
                  :placeholder="$t('label.h5.date.none')"
                >
                  <el-option
                    v-for="item in modeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
        <!-- 按钮组 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="showList = false" size="mini">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button @click="confirm" type="primary" size="mini">
            <!-- 确定 -->
            {{ $t("label.confirm") }}
          </el-button>
        </span>
      </el-dialog>
    </div>
    <div class="text-dialog">
      <!-- 文本弹窗 -->
      <!-- 添加文本 front-template-V1-addText-->
      <!-- 字号 front-template-V1-fontSize-->
      <el-dialog
        :title="$t('front-template-V1-addText')"
        :visible.sync="textDialogStatus"
        width="40%"
      >
        <div>
          <div class="text-box">
            <div class="te-Font-size">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <span class="te-Font-size-text">{{
                    $t("front-template-V1-fontSize")
                  }}</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(fontItem, fontIndex) in fontSizeList"
                    :key="fontIndex"
                    @click.native="choicePx(fontItem.id)"
                  >
                    {{ fontItem.id }}px
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="te-font-color">
              <colorPicker v-model="textColor" />
            </div>
            <div class="te-font-weight" @click="changeFontWeight">
              <h3
                :style="{
                  fontWeight:
                    textAttribute.TextisWeight == '1' ? 'bold' : 'normal',
                }"
              >
                B
              </h3>
            </div>
          </div>
          <div class="te-font">
            <textarea
              :style="{
                fontSize: `${textAttribute.TextFont}px`,
                color: textColor,
                fontWeight: textAttribute.TextisWeight ? 'bold' : 'normal',
              }"
              v-model="textValue"
            >
            </textarea>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="textDialogStatus = false">{{
            $t("label.cancel")
          }}</el-button>
          <el-button type="primary" @click="addText">{{
            $t("label.confirm")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
    <div class="addImg">
      <!-- 添加图片弹窗 -->
      <!-- 添加图片 front-template-V1-addImg-->
      <el-dialog
        :title="$t('front-template-V1-addImg')"
        :visible.sync="addImgStatus"
        width="700px"
        @close="clearImg"
      >
        <div>
          <div class="addImg-button">
            <el-upload
              action="/test"
              ref="addImgButton"
              :multiple="false"
              :limit="1"
              :auto-upload="false"
              accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
              :file-list="fileList"
              :on-change="onChange"
              :on-remove="onRemove"
            >
              <!-- 上传图片 label.weixin.upload.pictures-->
              <el-button type="primary">{{
                $t("label.weixin.upload.pictures")
              }}</el-button>
            </el-upload>
          </div>
          <div class="addImg-ImgShow">
            <img :src="dialogImageUrl" style="" alt="" />
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addImgStatus = false">{{
            $t("label.cancel")
          }}</el-button>
          <el-button type="primary" @click="uploadImg">{{
            $t("label.confirm")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
    <div>
      <!-- 模板预览 front-template-V1-TemplatePreview-->
      <el-dialog
        :title="$t('front-template-V1-TemplatePreview')"
        :visible.sync="PredialogStatus"
        width="50%"
      >
        <div>
          <embed
            type="application/pdf"
            :src="'data:application/pdf;base64,' + pdfSrc + '#toolbar=0'"
            width="100%"
            height="600px"
          />
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- 关闭 label.tabpage.closez-->
          <el-button type="primary" @click="PredialogStatus = false">{{
            $t("label.tabpage.closez")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 切换对象时提示是否保存 -->
    <div>
      <el-dialog
        title="提示"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="isSaveStatus"
        width="30%"
      >
        <span>是否保存当前模板内容</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelSave">取 消</el-button>
          <el-button type="primary" @click="submitSave">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 切换模板时提示是否保存 -->
    <div>
      <el-dialog
        title="提示"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="tabTemplateStatus"
        width="30%"
      >
        <span>是否保存当前模板内容</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelSaveT">取 消</el-button>
          <el-button type="primary" @click="submitSaveT">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <div></div>
  </div>
</template>

<script>
import Vue from "vue";
import * as ReportTemplate from "./api";
import * as objectTemplate from "./api";
import vuedraggable from "vuedraggable";
import VueCookies from "vue-cookies";
import inputType from "@/config/enumCode/inputType.js";
import EditableCell from "@/components/TablePanel/EditableCell.vue";
import operator from "@/config/enumCode/operator.js";
import vcolorpicker from "vcolorpicker";
Vue.use(vcolorpicker);
import { GetViewGetSelectValue, GetCurrencyInfo, getRecordType } from "./api";
import * as CommonObjApi from "./api";

export default {
  components: {
    EditableCell,
    vuedraggable,
  },
  data() {
    return {
      isTabTemplate: false,
      tabTemplateStatus: false,
      isSave: false,
      isSaveStatus: false,
      objFieldId: "",
      tocken: this.$CCDK.CCToken.getToken(),
      userUrl: VueCookies.get("domainName"),
      //文件流
      formData: "",
      fileList: [],
      //图片地址
      dialogImageUrl: "",
      //拖拽分组
      groupType: {
        group: {
          name: "fieldgroup",
          pull: "clone",
        },
      },
      //字号列表
      fontSizeList: [
        {
          id: "10",
          size: "10",
        },
        {
          id: "13",
          size: "13",
        },
        {
          id: "16",
          size: "16",
        },
        {
          id: "18",
          size: "18",
        },
        {
          id: "24",
          size: "24",
        },
        {
          id: "32",
          size: "32",
        },
        {
          id: "48",
          size: "48",
        },
      ],
      textColor: "#ff0000",
      //文本弹窗状态
      textDialogStatus: false,
      textValue: "",
      disabled: false,
      templateList: [],
      showFilter: "columns",
      objList: [],
      checkedObj: "",
      checkedObjLabel: this.$i18n.t("label.service.work.order.line"),
      fieldList: [],
      loadOption: true,
      showList: false,
      searchValue: "",
      searchObjectValue: "",
      deleteCurrentIndex: null,
      addCurrentIndex: null,
      isSelectPeoList: [],
      //父对象
      parentObject: "",
      // 保存筛选字段
      pushData: [
        {
          id: 0,
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          inputType: "input",
        },
      ],
      pushDatas: {
        fieldId: "",
        op: "",
        val: {
          value: "",
        },
        inputType: "input",
      },
      //筛选条件
      choseFieldList: [],
      conditions: [],
      selector: [],
      saveLoading: true,
      filter: "filter",
      showBut: [false],
      showSearchTable: false,
      fieldId: "",
      checked: false, //多选框选择和取消
      relevantObjId: "",
      relevantPrefix: "",
      prefix: this.$route.query.prefix,
      //模板默认数据
      templateContentList: [
        //新建部分 label.layout.new.built
        {
          title: this.$i18n.t("label.layout.new.built"),
          HideTitle: "0",
          leftData: [],
          rightData: [],
          iconStatus: false,
          type: "section",
        },
        {
          title: this.$i18n.t("label.layout.new.built"),
          HideTitle: "0",
          leftData: [],
          rightData: [],
          iconStatus: false,
          type: "section",
        },
        {
          title: this.$i18n.t("label.layout.new.built"),
          HideTitle: "0",
          leftData: [],
          rightData: [],
          iconStatus: false,
          type: "section",
        },
      ],
      //模板id
      templateid: "",
      Tsubid: "",
      templateName: "",
      //当前拖拽元素
      draggedContext: "",
      //部分弹窗状态码
      partStatus: false,
      //部分弹窗中输入框
      partinput: this.$i18n.t("label.layout.new.built"),
      //设置弹窗
      settinginput: "",
      //设置弹窗-状态
      settingStatus: false,
      //部分索引
      partIndex: 0,
      //列表标题
      //新建列表 lable.create.new.list
      listName: this.$i18n.t("lable.create.new.list"),
      //是否开启固定定位
      isFixed: false,
      DomWidth: 0,
      //子对象列表
      Subobjectlist: [],
      //子对象字段列表
      SubobjectFieldList: [],
      //搜索结果数组
      searchSubobjectFieldList: [],
      CurrentObject: "",
      //时间类型字段
      TimeTypeField: "",
      //时间类型字段列表
      TimeTypeFieldList: [],
      //排序
      mode: "",
      //排序类型
      modeList: [],
      //父对象字段列表
      ObjectField: [],
      //是否是编辑列表
      isEditor: false,
      //当前列表索引
      ListIndex: 0,
      //部分隐藏标题
      PartIsHide: false,
      //部分设置隐藏标题
      PartSetIsHide: false,
      //列表隐藏标题
      ListIsHide: false,
      reportid: "",
      //列表编辑下副对象id
      listEditId: "",
      //是否为保存并预览
      isviewTemplat: false,
      //文本域样式属性
      textAttribute: {
        TextFont: "10",
        TextisWeight: false,
      },
      //拖拽目标在列表中索引-最外层
      OutermostLayerIndex: "",
      //拖拽目标在部分中索引
      InPartIndex: "",
      //拖拽目标在部分中左侧还是右侧
      leftOrRight: "",
      //可创建模板对象列表
      objectList: [],
      //添加图片弹窗
      addImgStatus: false,
      //类型
      templateType: "",
      //预览模板弹窗
      PredialogStatus: false,
      //pdf地址
      pdfSrc: "",
      //列表所需relatedFieldId
      listRelatedFieldId: "",
      //是否为编辑文本
      isEditText: false,
      //是否为编辑上传图片
      isEditUploadImg: false,
      //当前文本编辑坐标
      textEditIndex: {
        index: null,
        Sindex: null,
        leftOrRight: null,
      },
      //当前编辑上传图片坐标
      imgEditIndex: {
        index: null,
        Sindex: null,
        leftOrRight: null,
      },
      //当前记录类型
      TemplateRecordType: "",
      //记录类型数据
      recordTypeData: [],
      // 对象前缀
      recordPrefix: "",
    };
  },
  mounted() {
    this.DomWidth = this.$refs.DomBody.offsetWidth - 20;
    this.$Bus.$on("Ceiling-height", (scrollTop) => {
      this.isFixed = scrollTop > 70 ? true : false;
    });
    this.getCanCreateObjectList();
    // this.getObjLists(this.checkedObj);
    // this.getobjlist(this.checkedObj);
    //从列表页跳转过来请求所需要数据
    if (this.$route.query.TobjId) {
      this.getAllReportTemplate(this.$route.query.TobjId);
      this.getAssociatedObject(this.$route.query.TobjId);
      this.parentObject = this.$route.query.TobjId;
    }
    if ((this.$route.query.Tid, this.$route.query.Tsubid)) {
      this.getTemplateDetail(this.$route.query.Tid, this.$route.query.Tsubid);
    }
    if (this.$route.query.Tname) {
      this.templateName = this.$route.query.Tname;
      this.templateid = this.$route.query.Tid;
      this.Tsubid = this.$route.query.Tsubid;
      this.templateType = this.$route.query.Type;
    }
  },
  created() {},
  methods: {
    // 获取对象下的记录类型
    async getRecordType() {
      let resultData = await objectTemplate.getRecordType({
        prefix: this.recordPrefix,
      });
      this.recordTypeData = resultData?.data?.recordTypeList;
    },
    //限制文件类型
    isImage(fileName) {
      if (typeof fileName !== "string") return;
      let name = fileName.toLowerCase();
      return (
        name.endsWith(".png") ||
        name.endsWith(".jpeg") ||
        name.endsWith(".jpg") ||
        name.endsWith(".png") ||
        name.endsWith(".bmp")
      );
    },
    onChange(file, fileList) {
      // 拿到当前上传图片的name，判断其后缀名是否符合
      let type = this.isImage(file.name);
      this.fileList = fileList;
      let fileLength = this.fileList.length;
      if (!type) {
        // 若不符合图片类型，则让当前上传的文件去除掉即可，即从上传对列删除本次上传
        this.fileList.splice(fileLength - 1, 1);
        this.$message.error("只允许上传图片");
      }
      this.dialogImageUrl = URL.createObjectURL(file.raw);
      this.formData = file;
    },
    onRemove() {
      this.dialogImageUrl = "";
      this.formData = "";
    },
    //文本弹窗关闭

    textClose() {
      this.isEditText = false;
    },
    //弹窗关闭清除已选中图片
    clearImg() {
      this.$refs.addImgButton.clearFiles();
      this.isEditUploadImg = false;
      this.dialogImageUrl = "";
    },
    //设置上传图片弹窗
    imgSetting(index, leftOrRight, Sindex) {
      this.imgEditIndex.index = index;
      this.imgEditIndex.Sindex = Sindex;
      this.imgEditIndex.leftOrRight = leftOrRight;
      this.isEditUploadImg = true;
      this.addImgStatus = true;
    },
    //上传图片
    async uploadImg() {
      let formData = new FormData();
      formData.append("file", this.formData.raw);
      formData.append("fileName", this.formData.name);
      formData.append("groupid", "");
      formData.append("libid", "");
      formData.append("parentid", "");
      formData.append("isFromEmail", "");
      if (this.formData != "") {
        let resultData = await objectTemplate.upload(formData);
        if (resultData.result) {
          let obj = {};
          obj.fileid = resultData.data.fileinfoid;
          obj.isShow = false;
          obj.type = "img";
          //是否是编辑上传图片
          if (!this.isEditUploadImg) {
            if (this.leftOrRight == "left") {
              this.templateContentList[
                this.OutermostLayerIndex
              ].leftData.splice(this.InPartIndex, 0, obj);
            } else {
              this.templateContentList[
                this.OutermostLayerIndex
              ].rightData.splice(this.InPartIndex, 0, obj);
            }
          } else {
            if (this.imgEditIndex.leftOrRight == "leftData") {
              this.templateContentList[this.imgEditIndex.index].leftData.splice(
                this.imgEditIndex.Sindex,
                1,
                obj
              );
            } else {
              this.templateContentList[
                this.imgEditIndex.index
              ].rightData.splice(this.imgEditIndex.Sindex, 1, obj);
            }
          }
          this.addImgStatus = false;
        }
      } else {
        // 请选择图片 front-template-V1-Please-select-a-picture
        this.$message({
          message: this.$i18n.t("front-template-V1-Please-select-a-picture"),
          type: "warning",
        });
      }
    },
    //获取所选择适用对象的【查找字段关联对象+用户+组织】
    async getAssociatedObject(id) {
      let resultData = await objectTemplate.getCheckedRelatedObj({ objid: id });
      if (resultData.result) {
        this.objList = resultData.data;
        this.checkedObj = this.objList[0].objid;
        this.getObjLists(this.objList[0].objid);
        this.getobjlist(this.checkedObj);
        this.objFieldId = this.objList[0].fieldid;
      }
    },
    //获取可创建模板对象列表
    async getCanCreateObjectList() {
      let resultData = await objectTemplate.getCanCreatedObjList({});
      if (resultData.result) {
        this.objectList = resultData.data;
        if( this.objectList){
          this.recordPrefix = this.objectList.find((item) => {
            return item.id == this.parentObject;
          })?.prefix;
          this.getRecordType();
        }
      }
    },
    //设置文本
    textSetting(item, leftOrRight, index, Sindex) {
      this.textColor = item.Color;
      this.textAttribute.TextFont = item.fontSize;
      this.textAttribute.TextisWeight = item.fontWeight == "1" ? true : false;
      this.textValue = item.text;
      this.textEditIndex.leftOrRight = leftOrRight;
      this.textEditIndex.index = index;
      this.textEditIndex.Sindex = Sindex;
      this.isEditText = true;
      this.textDialogStatus = true;
    },
    //添加文本
    addText() {
      let obj = {};
      obj.type = "text";
      obj.isShow = false;
      obj.text = this.textValue;
      obj.fontSize = this.textAttribute.TextFont;
      obj.Color = this.textColor;
      if (this.textAttribute.TextisWeight) {
        obj.fontWeight = "1";
      } else {
        obj.fontWeight = "0";
      }
      //是否为编辑文本
      if (!this.isEditText) {
        if (this.leftOrRight == "left") {
          this.templateContentList[this.OutermostLayerIndex].leftData.splice(
            this.InPartIndex,
            0,
            obj
          );
        } else {
          this.templateContentList[this.OutermostLayerIndex].rightData.splice(
            this.InPartIndex,
            0,
            obj
          );
        }
      } else {
        let leftOrRight = this.textEditIndex.leftOrRight;
        if (leftOrRight == "leftData") {
          this.templateContentList[this.textEditIndex.index].leftData.splice(
            this.textEditIndex.Sindex,
            1,
            obj
          );
        } else {
          this.templateContentList[this.textEditIndex.index].rightData.splice(
            this.textEditIndex.Sindex,
            1,
            obj
          );
        }
      }
      this.textDialogStatus = false;
      this.textValue = "";
      this.textAttribute.TextFont = "10";
      this.textColor = "#ff0000";
      this.textAttribute.TextisWeight = false;
    },
    //当前文本大小选择
    choicePx(item) {
      this.textAttribute.TextFont = item;
    },
    //更改字体是否加粗
    changeFontWeight() {
      this.textAttribute.TextisWeight = !this.textAttribute.TextisWeight;
    },
    //已选字段置顶
    FieldTopping() {
      if (this.deleteCurrentIndex) {
        let deleteField = this.isSelectPeoList.splice(
          this.deleteCurrentIndex,
          1
        );
        this.isSelectPeoList.unshift(deleteField[0]);
        this.deleteCurrentIndex = 0;
      }
    },
    //已选字段置底
    FieldBottomSetting() {
      if (this.deleteCurrentIndex || this.deleteCurrentIndex == 0) {
        let deleteField = this.isSelectPeoList.splice(
          this.deleteCurrentIndex,
          1
        );
        this.isSelectPeoList.push(deleteField[0]);
        this.deleteCurrentIndex = this.isSelectPeoList.length - 1;
      }
    },
    //已选字段向上移
    FieldMoveUp() {
      if (this.deleteCurrentIndex) {
        let deleteField = this.isSelectPeoList.splice(
          this.deleteCurrentIndex,
          1
        );
        this.isSelectPeoList.splice(
          this.deleteCurrentIndex - 1,
          0,
          deleteField[0]
        );
        this.deleteCurrentIndex = this.deleteCurrentIndex - 1;
      }
    },
    //已选字段向下移
    FieldMoveDown() {
      if (this.deleteCurrentIndex == this.isSelectPeoList.length - 1) {
        return;
      }
      if (this.deleteCurrentIndex || this.deleteCurrentIndex == 0) {
        let deleteField = this.isSelectPeoList.splice(
          this.deleteCurrentIndex,
          1
        );
        this.isSelectPeoList.splice(
          this.deleteCurrentIndex + 1,
          0,
          deleteField[0]
        );
        this.deleteCurrentIndex = this.deleteCurrentIndex + 1;
      }
    },
    //重做
    redo() {
      this.getTemplateDetail(this.templateid, this.Tsubid);
    },
    //取消-返回模板列表
    cancelFun() {
      this.$router.push({
        path: "/systemSettings/setuppage/objectTemplatePrint",
      });
    },
    //当前为快速保存
    viewTemplate() {
      this.isviewTemplat = true;
      this.saveReportTemplate();
    },
    //切换主对象时遍历字段列表-如果已经在部分列表中则禁用该字段
    DisableField() {
      this.templateContentList.forEach((item) => {
        if (item.type == "section") {
          item.leftData.forEach((val) => {
            this.ObjectField.forEach((FieldItem, index) => {
              if (val.id == FieldItem.id) {
                this.ObjectField[index].isDisable = true;
              }
            });
          });

          item.rightData.forEach((val) => {
            this.ObjectField.forEach((FieldItem, index) => {
              if (val.id == FieldItem.id) {
                this.ObjectField[index].isDisable = true;
              }
            });
          });
        }
      });
    },
    //获取单个服务报告模板详情
    async getTemplateDetail(id, subid) {
      this.saveLoading = true;
      let resultData = await objectTemplate.getTemplateById({
        templateid: id,
        subid: subid,
      });
      this.reportid = subid;
      if (resultData.result) {
        this.saveLoading = false;
        this.listRelatedFieldId = resultData.data.schemetableID.id;
        this.TemplateRecordType = resultData.data.recordtype;
        let editTemplateArr = [];
        resultData.data.sectionList.forEach((item) => {
          if (item.type == "section") {
            let obj = {};
            obj.title = item.name;
            obj.HideTitle = item.hidetitle;
            obj.iconStatus = false;
            obj.type = item.type;
            obj.leftData = [];
            obj.rightData = [];
            item.fieldList.forEach((val) => {
              let fieldObj = {};
              //文本
              if (val.richtext != null) {
                fieldObj.type = "text";
                fieldObj.fontSize = val.textsize;
                fieldObj.Color = val.textcolor;
                fieldObj.fontWeight = val.textbold;
                fieldObj.text = val.richtext;
              }
              //图像
              if (val.fieldid == "ffe2021templateimg00") {
                fieldObj.type = "img";
                fieldObj.fieldid = "ffe2021templateimg00";
                fieldObj.fileid = val.fileid;
              }
              //系统字段
              if (val.fieldid && val.fieldid != "ffe2021templateimg00") {
                fieldObj.id = val.fieldid;
                fieldObj.labelName = val.fieldname;
                if (JSON.stringify(val.relatedfield) != "null") {
                  fieldObj.relatedFieldId = val.relatedfield;
                }
              }
              //空格
              if (
                JSON.stringify(val.fieldid) == "null" &&
                JSON.stringify(val.relatedfield) == "null" &&
                JSON.stringify(val.richtext) == "null"
              ) {
                fieldObj.type = "Space";
              }
              fieldObj.isShow = false;
              //部分左侧字段列表
              if (val.colIndex == 1) {
                obj.leftData.splice(val.rowIndex - 1, 0, fieldObj);
              }
              //部分右侧字段列表
              if (val.colIndex == 2) {
                obj.rightData.splice(val.rowIndex - 1, 0, fieldObj);
              }
            });
            editTemplateArr.splice(item.seq - 1, 0, obj);
          } else if (item.type == "list") {
            let listobj = {};
            listobj.title = item.name;
            listobj.HideTitle = item.hidetitle;
            listobj.iconStatus = false;
            listobj.type = item.type;
            listobj.MainObject = item.objid;
            listobj.objid = item.relatedlistid;
            listobj.tableData = [];
            item.fieldList.forEach((value) => {
              let fieldsObj = {};
              fieldsObj.id = value.fieldid;
              fieldsObj.labelName = value.fieldname;
              fieldsObj.type = value.schemefieldtype;
              listobj.tableData.splice(value.seq - 1, 0, fieldsObj);
            });
            listobj.OptionalField = item.unSelectFieldList;
            // ReportTemplate.getRelationList({ objId: item.objid })
            //   .then((objList) => {
            //     let SingleObject
            //     let allField
            //     objList.data.forEach((obj) => {
            //       if (obj.id == item.relatedlistid) {
            //         allField = obj.fieldList
            //         listobj.tableData.forEach((exists) => {
            //           allField.forEach((Field,index)=>{
            //               if(exists.id == Field.id){
            //                 allField.splice(index,1)
            //               }
            //           })
            //         })
            //       }
            //     })
            //     listobj.OptionalField = item.unSelectFieldList;

            //   })
            //   .catch(() => {})
            //筛选器回显
            let copydata = [];
            let allField = listobj.tableData.concat(item.unSelectFieldList);
            if (item.condList.length != 0) {
              item.condList.forEach((ele, index) => {
                copydata.push({
                  id: index,
                  fieldId: ele.fieldId,
                  op: ele.operator,
                  val: {
                    value: ele.value,
                  },
                  inputType: "input",
                });
              });
              copydata.forEach((nav) => {
                allField.forEach((em) => {
                  if (nav.fieldId == em.id) {
                    nav.type = em.type;
                    nav.inputType = inputType[em.type];
                    this.conditions =
                      operator.TYPE[nav.type].getScreeningOpeTypes();
                  }
                });
              });
              if (item.condList.length >= 0 && item.condList.length <= 1) {
                this.showBut = [false];
              } else {
                this.showBut.push(true);
                this.showBut[0] = true;
              }
            } else {
              let copyobj = [
                {
                  id: 0,
                  fieldId: "",
                  op: "",
                  val: {
                    value: "",
                  },
                  inputType: "input",
                },
              ];
              copydata = copyobj;
            }
            listobj.Filter = copydata;
            let sortObj = {};
            if (item.sortfield) {
              sortObj.sortField = item.sortfield;
            } else {
              sortObj.sortField = "";
            }

            if (item.sort) {
              sortObj.sortorder = item.sort;
            } else {
              sortObj.sortorder = "";
            }
            listobj.sort = sortObj;
            editTemplateArr.splice(item.seq - 1, 0, listobj);
          }
        });
        this.templateContentList = editTemplateArr;
        this.DisableField();
      }
    },
    //根据对象获取模板列表
    async getAllReportTemplate(id) {
      let resultData = await objectTemplate.getObjTemplateList({ objId: id });
      resultData.data.forEach((item) => {
        if (item.isenable == "1") {
          item.isenable = true;
        } else {
          item.isenable = false;
        }
      });
      this.templateList = resultData.data;
    },
    //保存模板
    async saveReportTemplate() {
      if (this.templateContentList.length < 1) {
        this.$message({
          message: this.$i18n.t("c1054"), //该模板下未创建内容，请设置好模板后再保存
          type: "warning",
        });
        return;
      }
      this.saveLoading = true;
      let params = {};
      let templateContentList = JSON.parse(
        JSON.stringify(this.templateContentList)
      );
      let Splicing = [];
      templateContentList.forEach((item, index) => {
        //如果是部分-拼参数
        if (item.type == "section") {
          //如果右侧数据大于左侧，则向左侧添加对应数量的空格----后端生成pdf需要用到
          if (item.leftData < item.rightData) {
            let num = item.rightData.length - item.leftData.length;
            for (let i = 0; i < num; i++) {
              let fieldObj = {};
              fieldObj.colIndex = 1;
              fieldObj.rowIndex = item.leftData.length + 1;
              fieldObj.reportid = this.reportid;
              fieldObj.seq = item.leftData.length + 1;
              item.leftData.push(fieldObj);
            }
          }
          //参数结构
          let obj = {};
          obj.hidetitle = item.HideTitle;
          obj.reportid = this.reportid;
          obj.name = item.title;
          obj.type = "section";
          obj.cols = "2";
          obj.seq = index + 1;
          obj.objid = this.parentObject;
          let fieldList = [];
          item.leftData.forEach((fieldleft, fieldleftIndex) => {
            let fieldObj = {};
            fieldObj.colIndex = 1;
            fieldObj.rowIndex = fieldleftIndex + 1;
            fieldObj.reportid = this.reportid;
            fieldObj.seq = fieldleftIndex + 1;
            //如果是系统字段
            if (
              fieldleft.type != "text" &&
              fieldleft.type != "img" &&
              fieldleft.type != "Space"
            ) {
              fieldObj.fieldid = fieldleft.id;
              if (fieldleft.relatedFieldId) {
                fieldObj.relatedfield = fieldleft.relatedFieldId;
              }
            }
            //如果是文本
            if (fieldleft.type == "text") {
              fieldObj.textsize = fieldleft.fontSize;
              fieldObj.textcolor = fieldleft.Color;
              fieldObj.textbold = fieldleft.fontWeight;
              fieldObj.richtext = fieldleft.text;
            }
            //如果是图像
            if (fieldleft.type == "img") {
              fieldObj.fieldid = "ffe2021templateimg00";
              fieldObj.fileid = fieldleft.fileid;
            }
            //如果是空格
            // eslint-disable-next-line no-empty
            if (fieldleft.type == "Space") {
            }
            fieldList.push(fieldObj);
          });
          item.rightData.forEach((fieldleft, fieldleftIndex) => {
            let fieldObj = {};
            fieldObj.colIndex = 2;
            fieldObj.rowIndex = fieldleftIndex + 1;
            fieldObj.reportid = this.reportid;
            fieldObj.seq = fieldleftIndex + 1;
            //如果是系统字段
            if (
              fieldleft.type != "text" &&
              fieldleft.type != "img" &&
              fieldleft.type != "Space"
            ) {
              fieldObj.fieldid = fieldleft.id;
              if (fieldleft.relatedFieldId) {
                fieldObj.relatedfield = fieldleft.relatedFieldId;
              }
            }
            //如果是文本
            if (fieldleft.type == "text") {
              fieldObj.textsize = fieldleft.fontSize;
              fieldObj.textcolor = fieldleft.Color;
              fieldObj.textbold = fieldleft.fontWeight;
              fieldObj.richtext = fieldleft.text;
            }
            //如果是图像
            if (fieldleft.type == "img") {
              fieldObj.fieldid = "ffe2021templateimg00";
              fieldObj.fileid = fieldleft.fileid;
            }
            //如果是空格
            // eslint-disable-next-line no-empty
            if (fieldleft.type == "Space") {
            }
            fieldList.push(fieldObj);
          });
          obj.fieldList = fieldList;
          Splicing.push(obj);
          //如果是列表-拼参数
        } else if (item.type == "list") {
          let obj = {};
          obj.hidetitle = item.HideTitle;
          obj.reportid = this.reportid;
          obj.name = item.title;
          obj.relatedlistid = item.objid;
          obj.objid = this.parentObject;
          obj.sortfield = item.sort.sortField;
          obj.sort = item.sort.sortorder;
          obj.type = "list";
          obj.seq = index + 1;
          let copydata = [];
          //筛选器
          if (item.Filter.length > 0) {
            for (var key in item.Filter) {
              var items = item.Filter[key];
              if (items.fieldId !== "") {
                if (items.val.value == "" || items.op == "") {
                  return false;
                } else if (items.val.value != "" && items.op != "") {
                  let paramsObj = {};
                  paramsObj.fieldId = items.fieldId;
                  paramsObj.operator = items.op;
                  if (items.val.value != "") {
                    if (Array.isArray(items.val)) {
                      items.val = items.val.toString();
                    } else {
                      items.val = items.val.value;
                    }
                    paramsObj.value = items.val;
                  }
                  paramsObj.seq = parseInt(key) + 1;
                  copydata.push(paramsObj);
                }
              }
            }
            obj.condList = copydata;
          } else {
            obj.condList = "";
          }
          let fieldList = [];
          item.tableData.forEach((value, index) => {
            let fieldObj = {};
            fieldObj.colIndex = index + 1;
            fieldObj.rowIndex = 1;
            fieldObj.reportid = this.reportid;
            fieldObj.seq = index + 1;
            fieldObj.relatedfield = this.listRelatedFieldId;
            fieldObj.fieldid = value.id;
            fieldList.push(fieldObj);
          });
          obj.fieldList = fieldList;
          Splicing.push(obj);
        }
      });
      params.data = Splicing;
      params.name = this.$route.query.Tname;
      params.id = this.templateid;
      params.recordtype = this.TemplateRecordType;
      let resultData = await objectTemplate.saveObjTemplate(params);
      if (resultData.result) {
        this.saveLoading = false;
        if (this.isSave) {
          this.tabObjectItem();
          return;
        }
        if (this.isTabTemplate) {
          this.getTemplateDetail(this.templateid, this.Tsubid);
          this.isTabTemplate = false;
          return;
        }
        //是否为保存并预览
        if (!this.isviewTemplat) {
          this.$router.push({
            path: "/systemSettings/setuppage/objectTemplatePrint",
            query: {
              id: this.parentObject,
            },
          });
          this.isviewTemplat = false;
        } else {
          this.previewTemplate(
            this.templateid,
            this.parentObject,
            this.templateType
          );
        }
      }
    },
    //预览模板

    async previewTemplate(id, objid, type) {
      let resultData = await objectTemplate.viewObjTemplate({
        id: id,
        objid: objid,
        type: type,
      });
      if (resultData.result) {
        this.isviewTemplat = false;
        this.pdfSrc = resultData.data.templatePdf;
        this.PredialogStatus = true;
      }
    },
    //主对象切换
    tabObject(prefix) {
      this.isSaveStatus = true;
      this.recordPrefix = prefix;
      this.getRecordType();
    },
    submitSave() {
      this.isSaveStatus = false;
      this.isSave = true;
      this.saveReportTemplate();
    },
    cancelSave() {
      this.isSaveStatus = false;
      this.tabObjectItem();
    },

    async tabObjectItem() {
      let resultData = await objectTemplate.getObjTemplateList({
        objId: this.parentObject,
      });
      if (resultData.result) {
        this.isSave = false;
        if (resultData.data.length == 0) {
          // 当前切换对象下没有可用模板 front-template-V1-noTemplate
          this.$message({
            message: this.$i18n.t("front-template-V1-noTemplate"),
            type: "warning",
          });
          return;
        } else {
          this.getAssociatedObject(this.parentObject);
          this.getTemplateDetail(
            resultData.data[0].templateid,
            resultData.data[0].subid
          );
          this.templateName = resultData.data[0].templatename;
          this.templateType = resultData.data[0].type;
          this.templateid = resultData.data[0].templateid;
          this.Tsubid = resultData.data[0].subid;
        }
      }
    },
    //列表弹窗开启
    openDialog() {
      this.showFilter = "columns";
    },
    //列表弹窗关闭
    closeDialog() {
      //是否编辑为false
      this.isEditor = false;
      this.clearList();
    },
    //对象字段为空
    objectFieldIsempty() {
      if (this.searchObjectValue == "") {
        this.ObjectField = this.fieldList;
        this.DisableField();
      }
    },
    //搜索对象字段列表
    searchObjectField() {
      if (this.searchObjectValue == "") {
        this.ObjectField = this.fieldList;
      } else {
        this.ObjectField = this.fieldList.filter((val) => {
          if (val.labelName) {
            return val.labelName
              .toUpperCase()
              .includes(this.searchObjectValue.toUpperCase());
          }
        });
      }
      this.DisableField();
    },
    //可选字段搜索清空后重新赋值
    isempty() {
      if (this.searchValue == "") {
        let copyArr = JSON.parse(JSON.stringify(this.SubobjectFieldList));
        this.isSelectPeoList.forEach((item) => {
          copyArr.forEach((value, Sindex) => {
            if (item.id == value.id) {
              copyArr.splice(Sindex, 1);
            }
          });
        });
        this.searchSubobjectFieldList = copyArr;
      }
    },
    //可选字段搜索
    searchField() {
      if (this.searchValue == "") {
        this.isempty();
      } else {
        let copyArr = JSON.parse(JSON.stringify(this.isSelectPeoList));
        this.searchSubobjectFieldList = JSON.parse(
          JSON.stringify(this.SubobjectFieldList)
        ).filter((item) => {
          return item.labelName
            .toUpperCase()
            .includes(this.searchValue.toUpperCase());
        });
        copyArr.forEach((Field) => {
          this.searchSubobjectFieldList.forEach((val, index) => {
            if (Field.id == val.id) {
              this.searchSubobjectFieldList.splice(index, 1);
            }
          });
        });
      }
    },
    chioceValue() {
      //升序 setup.layout.label.asc
      //降序 setup.layout.label.desc
      let list = [
        {
          value: "asc",
          label: this.$i18n.t("setup.layout.label.asc"),
        },
        {
          value: "desc",
          label: this.$i18n.t("setup.layout.label.desc"),
        },
      ];
      this.modeList = list;
    },
    //获取子对象字段列表
    getSubobjectFieldList(id) {
      this.TimeTypeField = "";
      this.mode = "";
      this.modeList = [];
      this.pushData = [
        {
          id: 0,
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          inputType: "input",
        },
      ];
      this.Subobjectlist.forEach((item) => {
        if (item.id == id) {
          this.SubobjectFieldList = item.fieldList;
          this.choseFieldList = item.fieldList;
          this.searchField();
          this.TimeTypeFieldList = item.fieldList.filter((val) => {
            return (
              val.schemefieldType == "D" ||
              val.schemefieldType == "N" ||
              val.schemefieldType == "F"
            );
          });
          this.isSelectPeoList = [];
        }
      });
    },
    //获取对象列表
    async getobjlist(objId) {
      let resultData = await objectTemplate.getRelationList({ objId: objId });
      if (resultData.result) {
        this.Subobjectlist = resultData.data;
        if (this.isEditor) {
          this.CurrentObject = this.listEditId;
        }
      }
    },
    //设置弹窗更改标题
    settingPartTitle() {
      this.templateContentList[this.partIndex].title = this.settinginput;
      this.settingStatus = false;
      if (this.PartSetIsHide) {
        this.templateContentList[this.partIndex].HideTitle = "1";
      } else {
        this.templateContentList[this.partIndex].HideTitle = "0";
      }
    },
    //设置弹窗
    settingPart(index, item) {
      if (item.type == "section") {
        this.settinginput = item.title;
        this.PartSetIsHide = item.HideTitle == "1" ? true : false;
        this.settingStatus = true;
        this.partIndex = index;
      } else if (item.type == "list") {
        this.ListIndex = index;
        //是否编辑为true
        this.isEditor = true;
        let copyItem = JSON.parse(JSON.stringify(item));
        this.listName = copyItem.title;
        this.ListIsHide = copyItem.HideTitle == "1" ? true : false;
        this.listEditId = copyItem.objid;
        this.getobjlist(this.checkedObj);
        this.getSubobjectFieldList(copyItem.objid);
        if (copyItem.OptionalField) {
          this.SubobjectFieldList = copyItem.OptionalField;
          copyItem.tableData.forEach((value) => {
            copyItem.OptionalField.forEach((val, index) => {
              if (value.id == val.id) {
                copyItem.OptionalField.splice(index, 1);
              }
            });
          });
          this.searchSubobjectFieldList = copyItem.OptionalField;
        }
        this.isSelectPeoList = copyItem.tableData;
        this.pushData = copyItem.Filter;
        this.TimeTypeField = copyItem.sort.sortField;
        //如果排序字段不为空则赋值选项
        if (this.TimeTypeField != "") {
          this.chioceValue();
        }
        this.mode = copyItem.sort.sortorder;
        this.showList = true;
      }
    },
    //移除当前部分
    removePart(index) {
      this.templateContentList.splice(index, 1);
    },
    //鼠标移入部分标题
    partEnter(index) {
      this.templateContentList[index].iconStatus = true;
    },
    //鼠标离开部分标题
    partLeave(index) {
      this.templateContentList[index].iconStatus = false;
    },
    //新增部分
    addPart() {
      let obj = {
        title: this.partinput,
        HideTitle: this.PartIsHide ? "1" : "0",
        leftData: [],
        rightData: [],
        iconStatus: false,
        type: "section",
      };
      this.templateContentList.splice(this.draggedContext.futureIndex, 0, obj);
      this.partStatus = false;
      this.PartSetIsHide = false;
      //新建部分 label.layout.new.built
      this.partinput = this.$i18n.t("label.layout.new.built");
    },
    //当前拖动目标为列表或部分
    NotField(e) {
      if (
        e.draggedContext.element.id == "0001" ||
        e.draggedContext.element.id == "0002" ||
        e.draggedContext.element.id == "0003" ||
        e.draggedContext.element.id == "0004"
      ) {
        this.draggedContext = e.draggedContext;
      }
    },
    //移除左侧字段
    removeLeftField(index, Sindex, id) {
      this.templateContentList[index].leftData.splice(Sindex, 1);
      this.ObjectField.forEach((item) => {
        if (item.id == id) {
          item.isDisable = false;
        }
      });
      // this.ReduceHeight();
    },
    //移除右侧字段
    removeRightField(index, Sindex, id) {
      this.templateContentList[index].rightData.splice(Sindex, 1);
      this.ObjectField.forEach((item) => {
        if (item.id == id) {
          item.isDisable = false;
        }
      });
      // this.ReduceHeight();
    },
    //鼠标移入左侧模板列表
    fieldListEnterLeft(index, Sindex) {
      this.templateContentList[index].leftData[Sindex].isShow = true;
    },
    //鼠标移出左侧模板列表
    fieldListLeaveLeft(index, Sindex) {
      this.templateContentList[index].leftData[Sindex].isShow = false;
    },
    //鼠标移入右侧模板列表
    fieldListEnterRight(index, Sindex) {
      this.templateContentList[index].rightData[Sindex].isShow = true;
    },
    //鼠标移出右侧模板列表
    fieldListLeaveRight(index, Sindex) {
      this.templateContentList[index].rightData[Sindex].isShow = false;
    },
    //遍历所有字段以获取当前拖拽的文本或图像的索引
    getTargetIndex() {
      this.templateContentList.forEach((item, listIndex) => {
        if (item.type == "section") {
          item.leftData.forEach((val, leftIndex) => {
            if (val.id == "0004" || val.id == "0003") {
              this.OutermostLayerIndex = listIndex;
              this.InPartIndex = leftIndex;
              this.leftOrRight = "left";
            }
          });

          item.rightData.forEach((val, rightIndex) => {
            if (val.id == "0004" || val.id == "0003") {
              this.OutermostLayerIndex = listIndex;
              this.InPartIndex = rightIndex;
              this.leftOrRight = "right";
            }
          });
        }
      });
    },
    //拖拽完成事件-标记被拖拽元素已存在，在字段列表中禁用
    isRepeat(e) {
      //当前拖拽的是部分-删除当前项、记录索引、打开弹窗
      //部分 label.sectionpart
      if (e.item.innerText == this.$i18n.t("label.sectionpart")) {
        this.templateContentList.splice(this.draggedContext.futureIndex, 1);
        this.partStatus = true;
      }
      //当前拖拽的是列表-删除当前项、记录索引、打开弹窗
      //列表 label.chatter.list
      //新建列表 lable.create.new.list
      if (e.item.innerText == this.$i18n.t("label.chatter.list")) {
        this.templateContentList.splice(this.draggedContext.futureIndex, 1);
        this.listName = this.$i18n.t("lable.create.new.list");
        this.CurrentObject = "";
        this.SubobjectFieldList = [];
        this.isSelectPeoList = [];
        this.showList = true;
        this.getobjlist(this.checkedObj);
      }
      //当前拖拽的是新建文本字段-删除当前项、记录索引、打开弹窗
      // 添加文本 front-template-V1-addText
      if (e.item.innerText == this.$i18n.t("front-template-V1-addText")) {
        this.getTargetIndex();
        if (this.leftOrRight == "left") {
          this.templateContentList[this.OutermostLayerIndex].leftData.splice(
            this.InPartIndex,
            1
          );
        } else {
          this.templateContentList[this.OutermostLayerIndex].rightData.splice(
            this.InPartIndex,
            1
          );
        }
        this.textDialogStatus = true;
      }
      //当前拖拽的是添加图片-删除当前项、记录索引、打开弹窗
      //添加图片 front-template-V1-addImg
      if (e.item.innerText == this.$i18n.t("front-template-V1-addImg")) {
        this.getTargetIndex();
        if (this.leftOrRight == "left") {
          this.templateContentList[this.OutermostLayerIndex].leftData.splice(
            this.InPartIndex,
            1
          );
        } else {
          this.templateContentList[this.OutermostLayerIndex].rightData.splice(
            this.InPartIndex,
            1
          );
        }
        this.addImgStatus = true;
      }
      this.templateContentList.forEach((item) => {
        if (item.leftData) {
          item.leftData.forEach((val) => {
            if (
              e.item.innerText == val.labelName &&
              e.item.innerText != this.$i18n.t("front-template-V1-addText") &&
              e.item.innerText != this.$i18n.t("front-template-V1-addImg")
            ) {
              this.ObjectField[e.oldIndex].isDisable = true;
              //如果当前选择关联对象等于适用对象或者是组织则不添加related_fieldId
              if (
                this.checkedObj != this.parentObject &&
                this.checkedObj != "2021organitioncustom"
              ) {
                item.relatedFieldId = this.checkedObj;
              }
            }
          });
        }
        if (item.rightData) {
          item.rightData.forEach((val) => {
            if (
              e.item.innerText == val.labelName &&
              e.item.innerText != this.$i18n.t("front-template-V1-addText") &&
              e.item.innerText != this.$i18n.t("front-template-V1-addImg")
            ) {
              this.ObjectField[e.oldIndex].isDisable = true;
              //如果当前选择关联对象等于适用对象或者是组织则不添加related_fieldId
              if (
                this.checkedObj != this.parentObject &&
                this.checkedObj != "2021organitioncustom"
              ) {
                item.relatedFieldId = this.checkedObj;
              }
            }
          });
        }
      });
    },
    //字段列表拖拽前点击事件
    chooseType(e) {
      this.disabled = true;
      this.PartIsHide = false;
      this.ListIsHide = false;
      //如果当前选中项为部分,则更改拖拽组件group值
      if (
        e.item.innerText == this.$i18n.t("label.chatter.list") ||
        e.item.innerText == this.$i18n.t("label.sectionpart")
      ) {
        this.groupType.group.name = "partList";
      } else {
        this.groupType.group.name = "fieldgroup";
      }
      this.addMeaninglessData();
    },
    //拖拽组件初始状态数组不能为空，否则拖拽失效，为空的情况下向数组添加一个无意义的对象，只要有值就删除此空对象
    addMeaninglessData() {
      this.templateContentList.forEach((item) => {
        if (item.leftData && item.leftData.length == 0) {
          let obj = {
            id: "isempty",
          };
          item.leftData.push(obj);
        } else if (item.leftData && item.leftData.length > 1) {
          item.leftData = item.leftData.filter((item) => {
            return item.id != "isempty";
          });
        }

        if (item.rightData && item.rightData.length == 0) {
          let obj = {
            id: "isempty",
          };
          item.rightData.push(obj);
        } else if (item.rightData && item.rightData.length > 1) {
          item.rightData = item.rightData.filter((item) => {
            return item.id != "isempty";
          });
        }
      });
    },
    //字段列表选中后松开事件
    unchooseType() {
      this.disabled = false;
    },
    //拖拽目标列表松开选中事件
    unchooseDisabledleft() {
      this.disabled = false;
    },
    chooseDisabledleft() {
      this.addMeaninglessData();
      this.disabled = true;
    },
    unchooseDisabledright() {
      this.disabled = false;
    },
    chooseDisabledright() {
      this.addMeaninglessData();
      this.disabled = true;
    },
    cancelSaveT() {
      this.tabTemplateStatus = false;
      this.getTemplateDetail(this.templateid, this.Tsubid);
    },
    submitSaveT() {
      this.tabTemplateStatus = false;
      this.isTabTemplate = true;
      this.saveReportTemplate();
    },
    choseTemplate(index, id, subid, type) {
      this.tabTemplateStatus = true;
      this.templateName = this.templateList[index].templatename;
      this.templateType = type;
      this.templateid = id;
      this.Tsubid = subid;
    },

    choseObj(item, index) {
      this.checkedObjLabel = this.objList[index].name;
      this.updateObj(item);
    },
    //新增列表
    confirm() {
      if (this.isSelectPeoList.length != 0) {
        let copyFilter = JSON.parse(JSON.stringify(this.pushData));
        let obj = {
          //标题
          title: this.listName,
          //隐藏标题
          HideTitle: this.ListIsHide ? "1" : "0",
          //主对象id
          MainObject: this.checkedObj,
          //副对象id
          objid: this.CurrentObject,
          //可选字段
          OptionalField: this.SubobjectFieldList,
          //已选字段
          tableData: this.isSelectPeoList,
          //图标状态
          iconStatus: false,
          //类型
          type: "list",
          //过滤器
          Filter: copyFilter,
          //排序
          sort: {
            sortField: this.TimeTypeField,
            sortorder: this.mode,
          },
        };
        //过滤器赋值
        if (this.$refs.editableCell) {
          this.$refs.editableCell.forEach((item, index) => {
            if (item.editValue !== "") {
              obj.Filter[index].val.value = item.editValue;
            } else {
              obj.Filter[index].value = item.value;
            }
          });
        }
        //如果是编辑则删除当前项，并重新赋值
        if (this.isEditor) {
          this.templateContentList.splice(this.ListIndex, 1);
          this.templateContentList.splice(this.ListIndex, 0, obj);
        } else {
          this.templateContentList.splice(
            this.draggedContext.futureIndex,
            0,
            obj
          );
        }
        this.showList = false;
        this.clearList();
      } else {
        this.$message.error(this.$i18n.t("label.empty.field"));
      }
    },
    //清除列表数据
    //新建列表 lable.create.new.list

    clearList() {
      this.listName = this.$i18n.t("lable.create.new.list");
      this.TimeTypeField = "";
      this.mode = "";
      this.modeList = [];
      this.SubobjectFieldList = [];
      this.searchSubobjectFieldList = [];
      this.isSelectPeoList = [];
      this.pushData = [
        {
          id: 0,
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          inputType: "input",
        },
      ];
    },

    delDialogVisible() {
      this.showList = false;
    },
    changeContent(data) {
      this.showFilter = data;
    },
    // 改变查找/查找带值选项及值
    changeSelect(row) {
      // let value = {
      //   value: row.data.id,
      // };
      let options = {
        label: row.data.id,
        value: row.data.name,
      };
      this.$set(this.pushData[this.rowIndex - 1], "val", options);
      this.showSearchTable = false;
    },
    remoteSearch(item, index) {
      this.rowIndex = index + 1;
      this.fieldId = "ffe100000001265aWyi8";
      this.relevantObjId = "user";
      this.relevantPrefix = "005";
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    remoteMethods(val, fieldId, objId) {
      let params = {
        fieldId: fieldId,
        objId: objId,
        prefix: this.prefix,
      };
      CommonObjApi.getLookupInfo(params).then(() => {
        let data = {
          name: val,
          objId: params.prefix,
          fieldId: params.fieldId,
          lkdp: "['']",
        };
        CommonObjApi.queryMoreDataByName(data).then((res) => {
          let options = JSON.parse(res.data);
          options.forEach((option) => {
            option.value = option.id;
            option.label = option.queryname;
          });
          this.selector = options;
        });
      });
    },
    //获取对象字段列表
    async getObjLists(objId) {
      let obj = { objId };
      let result = await ReportTemplate.getFieldList(obj);
      if (result.data) {
        result.data.forEach((item, index) => {
          item.isDisable = false;
          item.isShow = false;
          if (!item.labelName && !typeof item.labelName != "undefined") {
            result.data.splice(index, 1);
          }
        });
        this.fieldList = result.data;
        if (objId == "user" || objId == "2021organitioncustom") {
          this.fieldList.splice(0, 0, {
            id: "0002",
            labelName: this.$i18n.t("label.sectionpart"),
          });
          this.fieldList.splice(1, 0, {
            id: "0003",
            labelName: this.$i18n.t("front-template-V1-addImg"),
          });
          this.fieldList.splice(2, 0, {
            id: "0004",
            labelName: this.$i18n.t("front-template-V1-addText"),
          });
        } else {
          this.fieldList.unshift({
            id: "0001",
            labelName: this.$i18n.t("label.chatter.list"),
          });
          this.fieldList.splice(1, 0, {
            id: "0002",
            labelName: this.$i18n.t("label.sectionpart"),
          });
          this.fieldList.splice(2, 0, {
            id: "0003",
            labelName: this.$i18n.t("front-template-V1-addImg"),
          });
          this.fieldList.splice(3, 0, {
            id: "0004",
            labelName: this.$i18n.t("front-template-V1-addText"),
          });
        }
        this.loadOption = false;
        this.searchObjectField();
        this.DisableField();
      }
    },
    updateObj(item) {
      this.loadOption = true;
      this.checkedObj = item.objid;
      this.objFieldId = item.fieldid;
      this.checkedObjLabel = item.labelName;
      this.getObjLists(item.objid);
      this.getobjlist(this.checkedObj);
    },
    //删除当前选中的对象
    deletePeo(index) {
      var result = this.isSelectPeoList.splice(index, 1);
      let num = this.searchSubobjectFieldList.findIndex((res) => {
        res.id == result[0].id;
      });

      if (num < 0) {
        this.searchSubobjectFieldList.push(result[0]);
      }
    },
    //选择搜索对象
    selectPeo(item) {
      this.searchSubobjectFieldList.map((Sitem, Sindex) => {
        if (Sitem.id == item.id) {
          this.searchSubobjectFieldList.splice(Sindex, 1);
        }
      });
      let index = this.isSelectPeoList.findIndex((res) => res.id == item.id);
      if (index < 0) {
        this.isSelectPeoList.push(item);
      }
    },
    //鼠标移入
    isSelectMouseOver(index) {
      this.deleteCurrentIndex = index;
    },
    //鼠标移入
    mouseOver(index) {
      this.addCurrentIndex = index;
    },
    //清空筛选器数据
    clear() {
      this.pushData = [
        {
          id: 0,
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          inputType: "input",
        },
      ];
    },
    //获取对象字段列表

    async getobjfieldList(id) {
      let fieldlist = await CommonObjApi.getFieldList(id);
      this.timeField = fieldlist.data.filter((item) => {
        return item.type == "F";
      });
    },
    //判断选择添加如果选择字段获取的值为S则调用S对象,获取的值为D则调用D对象,获取的值为F则调用F对象,获取的值为O则调用O对象
    async handleCommand(item, key, index) {
      this.selector = [];
      let keys = "";
      if (key.schemefieldType === "Z") {
        keys = key.expressionType;
      } else {
        keys = key.schemefieldType;
      }
      this.conditions = operator.TYPE[keys].getScreeningOpeTypes();
      this.pushData.map((item) => {
        if (index.id === item.fieldId) {
          // item.op = "";
          // item.type =
          key.schemefieldType === "Z"
            ? key.expressionType
            : key.schemefieldType;
          // this.$refs.editableCell[indexs].editValue = "";
        }
      });
      this.pushData.forEach((items) => {
        if (item.id === items.id) {
          item.inputType = inputType[keys];
        }
      });
      // 当前选中字段类型
      this.conditions = operator.TYPE[keys].getScreeningOpeTypes();
      if (keys == "ct") {
        let res = await GetCurrencyInfo();
        res.data.forEach((item) => {
          let obj = {
            label: item.currencyname,
            value: item.currencycode,
          };
          this.selector.push(obj);
        });
      }
    },
    updateItem(handle, index) {
      if (handle == "add") {
        if (this.pushData.length <= 4) {
          this.pushData.push({
            ...this.pushDatas,
          });
          if (this.pushData.length >= 0 && this.pushData.length <= 1) {
            this.showBut = [false];
          } else {
            this.showBut.push(true);
            this.showBut[0] = true;
          }
        }
      } else {
        this.pushData.splice(index, 1);
      }
    },
    async selectes(val) {
      if (val.type === "R") {
        this.getRecordTypes();
      } else {
        if (val.fieldId === "ffe20120329185449704") {
          return;
        }
        let arr = [];
        let params = {
          fieldId: val.fieldId,
        };
        let res = await GetViewGetSelectValue(params);
        if (res.result) {
          res.data.forEach((item) => {
            let obj = {
              label: item.codekey,
              value: item.codekey,
            };
            arr.push(obj);
            this.selector = arr;
          });
        }
      }
    },
    // 获取国家或地区选项列表值

    getCountrySelectValue() {
      GetViewGetSelectValue({ fieldId: "country" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let options = [];
          res.data.forEach((item) => {
            options.push({
              label: item.codevalue,
              value: item.codekey,
            });
          });
          this.selector = options;
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },

    async getRecordTypes() {
      let arr = [];
      let params = {
        prefix: this.prefix,
      };
      let res = await getRecordType(params);
      if (res.result) {
        res.data.recordTypeList.forEach((item) => {
          let obj = {
            label: item.name,
            value: item.name,
          };
          arr.push(obj);
          this.selector = arr;
        });
      }
    },
  },
  watch: {
    pushData(val) {
      if (val.length == 1) {
        this.showBut[0] = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
}
.range {
  .Requiredtype:first-child {
    color: red;
  }
  ::v-deep .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  ::v-deep .el-input__icon {
    line-height: 30px;
  }
  ::v-deep .created-type {
    width: 120px;
    margin-right: 6px;
  }
  ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;
  }

  ::v-deep .el-date-editor {
    display: none !important;
  }
}
.text-setting {
  margin-left: 5px;
}
.SpaceField {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  span:first-child {
    color: #fff;
  }
}
.textField {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  span:first-child {
    width: 400px;
    word-wrap: break-word;
    word-break: break-all;
  }
  .remove-field {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 20px;
  }
}
.imgField {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .remove-field {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 20px;
  }
}
.addImg {
  ::v-deep .el-dialog__body {
    padding: 10px 36px !important;
    overflow: visible;
  }
  .addImg-button {
    padding: 20px 0 20px 12px;
    background-color: #f0f0f0;
  }
  .addImg-ImgShow {
    min-height: 300px;
    border: 1px solid #dcdcdc;
    img {
      display: inline-block;
      height: auto;
      max-width: 100%;
    }
  }
}
.text-box {
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  padding: 20px 0 20px 12px;
}
.text-dialog {
  ::v-deep .el-dialog__body {
    padding: 0 !important;
    overflow: visible;
  }
  textarea {
    resize: none;
    outline: none;
    border: none;
    width: 100%;
    height: 298px;
    padding: 0;
  }
}
.te-font {
  padding: 10px;
}
.te-Font-size {
  background-color: #fff;
  border: 1px solid #dddbda;
  width: 96px;
  height: 30px;
  line-height: 30px;
  .te-Font-size-text {
    font-size: 12px;
    color: #333333;
  }
  ::v-deep .el-dropdown {
    width: 100%;
  }
  ::v-deep .el-dropdown-link {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
.te-font-color {
  background-color: #fff;
  margin-left: 12px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.te-font-weight {
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-left: 12px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.contentDataItemLeft {
  border-bottom: 1px solid rgba(221, 219, 218, 1);
  border-right: 1px solid rgba(221, 219, 218, 1);
}
.contentDataItemRight {
  border-bottom: 1px solid rgba(221, 219, 218, 1);
  border-left: 1px solid rgba(221, 219, 218, 1);
}
.PositionFunction {
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  width: 40px;
  i {
    padding: 15px 5px;
    font-size: 20px;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    cursor: pointer;
  }
  i:first-child {
    border-top: 1px solid #d8d8d8;
  }
  i:last-child {
    border-bottom: 1px solid #d8d8d8;
  }
}
.sort {
  padding: 15px 10px;
  p {
    font-size: 14px;
    color: #666666;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .sortOrder {
    margin-right: 15px;
  }
}

.fixedDom {
  position: fixed;
  top: 177px;
  z-index: 999;
  background-color: #f0f0f0;
  border: 1px solid #dcdcdc;
}
.listStyle {
  table {
    border-collapse: collapse;
    width: 100%;
    th,
    td {
      padding: 10px;
    }
    th {
      background-color: #f0f0f0;
      font-size: 14px;
      color: #333333;
    }
    td {
      font-size: 14px;
      color: #333333;
      background-color: #fff;
      text-align: center;
    }
  }
  table,
  th {
    border: 1px solid #dcdcdc;
  }
}
.layoutBox {
  display: flex;
  height: 100%;
  .layoutBox-left {
    width: 50%;
    min-height: 40px;
    .contentDataItem-child-left {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .fieldName {
        width: 139px;
        display: inline-block;
        text-align: left;
        overflow: hidden;
        cursor: move;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .sampleText {
        cursor: move;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .remove-field {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 20px;
      }
    }
  }
  .layoutBox-right {
    width: 50%;
    min-height: 40px;
    .contentDataItem-child-right {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .fieldName {
        width: 139px;
        display: inline-block;
        text-align: left;
        overflow: hidden;
        cursor: move;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .sampleText {
        cursor: move;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .remove-field {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 20px;
      }
    }
  }
}
.partTitle {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #dddedf;
  .contentTitle {
    margin-bottom: 0;
  }
  .optionIcon {
    i {
      cursor: pointer;
    }
    i:last-child {
      margin-left: 5px;
    }
  }
}
.partDailog {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-right: 10px;
  }
}
.titleHide {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.contentDataItem {
  padding: 10px;
  display: flex;
}
.contentDataItem > div:nth-child(1) {
  margin-right: 10px;
}
.contentData {
  background: #ffffff;
  margin-bottom: 10px;
  min-height: 40px;
  padding-bottom: 10px;
}
.listData {
  background: #ffffff;
  margin-bottom: 10px;
}
.contentTitle {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.moduleT {
  padding: 10px 30px 20px 30px;
  background: #f5f5f5;
}
.templateContent {
  width: 100%;
  margin-bottom: 10px;
}
.titleArea {
  width: 100%;
  height: 50px;
  border-radius: 3px 3px 0px 0px;
  border-bottom: 1px solid #dcdcdc;
  align-items: center;
}
.body {
  padding: 19px 10px;
}
.setting {
  background: #f0f0f0;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
}
.btnGroup {
  display: flex;
  height: 50px;
  padding: 12px 13px 12px 13px;
  border-bottom: 1px solid #dcdcdc;
}
.btnGroup span {
  margin: 0px 20px 0px 10px;
}
::v-deep .el-button {
  line-height: 0px;
}
::v-deep [class^="el-icon-"] {
  line-height: 0;
}
.dataList {
  display: flex;
  max-height: 199px;
}
.objList {
  width: 14%;
  border-right: 1px solid #dcdcdc;
  padding-bottom: 5px;
  overflow-y: auto;
}
.data {
  width: 86%;
  padding: 14px 13px;
  overflow-y: auto;
}
.objItem {
  margin-top: 5px;
  height: 30px;
  line-height: 30px;
  padding-left: 13px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
.search {
  display: flex;
  align-items: center;
}
::v-deep .el-input__prefix {
  top: 2px;
}
::v-deep .el-input__inner {
  height: 24px;
  font-size: 12px;
  cursor: pointer;
}
::v-deep .el-input {
  width: 190px;
}
::v-deep .el-icon-warning:before {
  color: #006dcc;
}
::v-deep .el-input__icon {
  line-height: 24px;
}
.listF {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.inputItem {
  position: relative;
  margin-right: 7px;
  margin-bottom: 7px;
  font-size: 14px;
  width: 139px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff;
  cursor: move;
}
.DisableInputItem {
  position: relative;
  margin-right: 7px;
  margin-bottom: 7px;
  font-size: 14px;
  width: 139px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #f5f5f5;
  color: #aca899;
}
.fieldtext {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
  padding: 4px 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
input:focus {
  outline: none;
}
.backgroundColor {
  background: linear-gradient(180deg, #ffffff 1%, #cbe7ff 96%);
  padding-left: 40px;
}
.position {
  position: absolute;
  left: 6px;
  top: 16px;
}
.position img {
  width: 16px;
  height: 16px;
}
.bodyContent {
  padding: 36px;
}
.titlePart {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.bodyTitle {
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}
.bodyList {
  border-radius: 3px;
  border: 1px solid #dcdcdc;
}
.listButton {
  padding: 5px 8px;
  border-bottom: 1px solid #dcdcdc;
  background: #f0f0f0;
}
.fieldTitle {
  font-size: 12px;
  color: #080707;
  margin-bottom: 10px;
}
.content {
  padding: 14px 8px;
}
.contentTitle {
  font-size: 14px;
  color: #666666;
  margin-bottom: 10px;
}
::v-deep .singleObj {
  padding: 7px 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.objTitle {
  cursor: pointer;
  font-size: 12px;
}
.objTitle:hover {
  background: #f5f5f5 !important;
  color: #006dcc !important;
}
::v-deep .el-button {
  height: 30px;
}
.hasListLeft {
  margin-right: 20px;
}
.hasList {
  float: left;
  width: 200px;
  height: 290px;
  color: #333;
  font-size: 12px;
  .title {
    margin-bottom: 5px;
    font-weight: 500;
  }
  .isSelect {
    float: right;
  }
  .bodyS {
    border: 1px solid #d8d8d8;
    overflow: auto;
    width: 100%;
    height: 270px;
    border-radius: 3px;
    padding-top: 30px;
    .list {
      overflow: hidden;
      .list-item {
        width: 100%;
        height: 30px;
        line-height: 30px;
        padding-left: 10px;
        .name {
          // width: 140px;
          // width: 100%;
          max-width: 150px;
          float: left;
          padding-right: 5px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .symbol {
          float: right;
          margin-right: 15px;
          cursor: pointer;
          img {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
    .input-box {
      width: 100%;
      padding: 0 5px;
      box-sizing: border-box;
      img {
        width: 16px;
        height: 16px;
        position: absolute;
        left: 7px;
        top: 7px;
      }
    }
    input {
      height: 30px;
      outline: none;
      border: none;
      border-bottom: 1px solid #dcdcdc;
      padding-left: 30px;
      // background: transparent;
    }
  }
}
.inputTitle {
  width: 187px;
  position: absolute;
  left: 2px;
  top: 1px;
}
::v-deep .el-dialog {
  margin-top: 10vh !important;
}
.range {
  margin-top: 10px;
}
.iconPosition {
  position: absolute;
  left: 150px;
  top: 8px;
}
.chosseField {
  width: 164px;
  height: 70px;
  background-color: pink;
  position: absolute;
  left: 10px;
  right: 0px;
}
.intro {
  display: flex;
}
.choseFieldList {
  font-family: SourceHanSansCN-Regular;
  font-size: 14px;
  color: #333333;
  padding-bottom: 20px;
  height: 400px;
  overflow: auto;
}
.choseFieldList li {
  padding: 20px 20px 0px 20px;
}
::v-deep .el-dropdown-menu {
  height: 400px;
  overflow: auto;
}
::v-deep .el-input__inner {
  width: 170px;
  height: 32px;
}
::v-deep .el-input__icon {
  line-height: 20px;
}
::v-deep .el-form-item__label {
  line-height: 32px;
}
.vl {
  display: inline-block;
}
::v-deep .el-form-item__content {
  line-height: 27px;
}
::v-deep .el-select {
  margin-right: 10px;
}
::v-deep .el-form-item__label {
  line-height: 42px;
}
::v-deep .el-form-item {
  margin-bottom: 5px;
}
::v-deep .remoteBtn {
  position: absolute;
  right: 8px;
  top: 2px;
}
::v-deep .el-button--mini,
.el-button--mini.is-round {
  padding: 7px 11px;
}
::v-deep .el-button--info {
  background: #ffffff;
  border-color: #dcdfe6;
}
::v-deep .el-button--info {
  color: black;
}
::v-deep .el-date-editor {
  display: none !important;
}
::v-deep .el-input {
  width: 160px !important;
}
// ::v-deep .el-textarea__inner {
//   transform: translateY(4px);
//   min-height: 32px !important;
// }
::v-deep .remoteBtn1 {
  top: 3px;
}
</style>
